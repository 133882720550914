<script>
import axios from "axios";
import SliderInCategory from "@/components/blocks/SliderInCategory.vue";
import Slider from "@/components/blocks/Slider.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import sklonyator from 'sklonyator';

export default {
  name: 'NewProfilesBlock',
  components: {Slider, SliderInCategory, UiLoader, sklonyator},
  props: {
    categoryId: {
      required: false,
      type: String
    },
    titleCards: {
      required: false,
      type: String
    },
    flagFilter: {
      required: false,
      type: String
    }
  },
  methods: {
    chunkSizeFunc(data) {
      const chunkSize = 4;
      for (let i = 0; i < data.length; i += chunkSize) {
        this.pages.push(data.slice(i, i + chunkSize));
      }
      this.profiles = this.pages[0]
    },
    getLastProfiles() {
      let params = ''
      let method = ''

      if (this.flagFilter === 'Service') {
        method = 'api-girl/get-profiles-by-service-id'
        params =
            '&service_id=' + this.$route.params.service_id
            + '&limit=8'
            + '&order=date_add'
            + '&order_type=desc'
            + `&city_id=${this.user.city?.id || 1}`
      } else if (this.flagFilter === 'Tag') {
        method = 'api-girl/get-profiles-by-params'
        params = '&tag_id=' + this.$route.params.tag_id + '&order_type=desc' + '&order=date_add' + `&city_id=${this.user.city?.id || 1}`
      } else {

        method = 'api-girl/get-profiles-list'
        params = "&status_id=6" + `&category_id=${this.categoryId}` + "&limit=8" + "&order_type=desc" + "&order=date_add" + `&city_id=${this.user.city?.id || 1}`
      }

      this.sklonyator()
      axios.get(this.apiUrl
          + method
          + '&auth=' + this.user.username + ':' + this.user.auth_key
          + params
      )
          .then((response) => {
            if (!this.flagFilter) {
              this.chunkSizeFunc(response.data)
            } else {
              this.chunkSizeFunc(response.data.data)
            }
          }).catch(error => console.log(error))
    },
    displayNext() {
      if(!this.profiles) return

      this.profiles = this.profiles.concat(this.pages[this.lastView + 1])

      this.lastView++;

      let next = this.lastView + 1;
      if (!this.pages[next]) {
        this.showBtn = false
      }
    },
    sklonyator() {
      this.city = sklonyator.incline(this.user.city.name);
    }
  },
  mounted() {
    this.getLastProfiles()
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      block: {
        title: 'Новые анкеты'
      },
      profiles: [],
      pages: [],
      btnText: 'Показать ещё',
      showBtn: true,
      lastView: 0,
      city: '',
      showLoader: false,
    }
  },
  computed: {
    userCity() {
      return this.$store.getters.getCity
    }
  },
  watch: {
    userCity: function () {
      this.showBtn = true
      this.pages = []
      this.lastView = 0
      this.getLastProfiles()
    }
  }
}
</script>

<template>
  <div class="block-offer__top">
    <div class="title block-offer__title">
      {{ this.block.title }}
      <span v-if="titleCards">{{ titleCards }}</span>
      <span v-if="city"> в {{ city }}</span>
    </div>
    <div class="block-offer__strip">

    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-3" v-for="p in this.profiles" :key="p.id">
      <Slider :profile="p"/>
    </div>
  </div>
  <div class="row mt-3 justify-content-center" v-if="showBtn">
    <div class="col-auto">
      <button @click="this.displayNext" class=" hover-btn">{{ this.btnText }}</button>
    </div>
  </div>

</template>

<style scoped>
button {
  background-color: #72666A;
  color: #FFFFFF;
  border-radius: 25px;
  padding: 8px 22px;
  font-size: 16px;
  font-weight: 500;
}

button:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
}
</style>